<template>
  <div>
    <div class="m-subheader">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Serviços
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link
                :to="{ name: 'home' }"
                class="m-nav__link m-nav__link--icon"
              >
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">-</li>
            <li class="m-nav__item">
              <span class="m-nav__link-text">Gestão de Serviços</span>
            </li>
          </ul>
        </div>
        <div>
          <!-- aqui -->
          <button
            v-if="$authorization.can('service-store')"
            class="
              btn btn-outline-danger
              m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill
            "
            type="button"
            data-toggle="modal"
            data-target="#service-store"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>

        <div class="ml-1">
          <!-- report xlsx -->
          <button
            class="
              btn btn-outline-success
              m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill
            "
            type="button"
            data-toggle="modal"
            data-target="#export-xlsx"
          >
            <i class="fa fa-download"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="m-content">
      <div class="row">
        <div class="col-12">
          <div class="m-portlet m-portlet--full-height">
            <div class="m-portlet__body">
              <div class="form-group m-form__group row border-bottom pb-4 mb-5">
                <div class="col-lg-3">
                  <v-select
                    :value="filter.service"
                    placeholder="Serviço"
                    :options="filterServices"
                    :onChange="(value) => filterService(value, 'service')"
                  >
                    <span slot="no-options">Nenhum resultado.</span>
                  </v-select>
                </div>
                <div class="col-lg-3">
                  <v-select
                    :value="filter.company"
                    placeholder="Provedor"
                    :clearSearchOnSelect="false"
                    :options="filterCompanies"
                    :onChange="(value) => filterService(value, 'company')"
                  >
                    <span slot="no-options">Nenhum resultado.</span>
                  </v-select>
                </div>

                <div class="col-lg-2">
                  <input
                    type="text"
                    class="form-control filter-input"
                    placeholder="Sharecode"
                    v-model="filter.sharecode"
                    @keyup="filterService(filter.sharecode, 'sharecode')"
                    @keyup.enter="filterService(filter.sharecode, 'sharecode')"
                  />
                </div>

                <div class="col-lg-2">
                  <v-select
                    :value="filter.statusRegister"
                    placeholder="Status de Cadastro"
                    :clearSearchOnSelect="false"
                    :options="filterStatusRegister"
                    :onChange="
                      (value) => filterService(value, 'statusRegister')
                    "
                  >
                    <span slot="no-options">Nenhum resultado.</span>
                  </v-select>
                </div>

                <div class="col-lg-2">
                  <v-select
                    :value="filter.status"
                    placeholder="Status"
                    :clearSearchOnSelect="false"
                    :options="filterStatus"
                    :onChange="(value) => filterService(value, 'status')"
                  >
                    <span slot="no-options">Nenhum resultado.</span>
                  </v-select>
                </div>
              </div>
              <u-table :columns="columns">
                <tr v-if="isLoading">
                  <td
                    colspan="9"
                    class="text-center pt-4 pb-4 m-auto"
                    align="center"
                  >
                    <div
                      class="m-loader m-loader--danger"
                      style="width: 30px; display: inline-block"
                    ></div>
                  </td>
                </tr>
                <tr
                  v-if="
                    !$authorization.can('services') ||
                    servicesNotFound ||
                    (!isLoading && !services.data.length)
                  "
                >
                  <td
                    colspan="9"
                    class="text-center pt-4 pb-4 m-auto"
                    align="center"
                  >
                    Nenhum serviço cadastrado
                  </td>
                </tr>
                <tr
                  v-if="!isLoading && services.data.length"
                  v-for="(service, index) in services.data"
                  :key="index"
                >
                  <td class="pt-4 pb-4">{{ service.name }}</td>
                  <td class="pt-4 pb-4">
                    {{
                      service.company_content
                        ? service.company_content.name
                        : " - "
                    }}
                  </td>
                  <td v-if="service.responsible" class="pt-4 pb-4">
                    {{
                      `${service.responsible.first_name} ${service.responsible.last_name}`
                    }}
                  </td>
                  <td v-else class="pt-4 pb-4">-</td>
                  <td class="pt-4 pb-4">
                    {{ $helper.convertDatePtBr(service.updated_at) }}
                  </td>
                  <td class="pt-4 pb-4">
                    <span
                      :class="`m-badge m-badge--${
                        support.registerBadge(service.status_register).color
                      } m-badge--wide`"
                      >{{
                        support.registerBadge(service.status_register).name
                      }}</span
                    >
                  </td>
                  <td class="pt-4 pb-4">
                    <span
                      :class="`m-badge m-badge--${
                        support.statusBadge(service.status).color
                      } m-badge--wide`"
                      >{{ support.statusBadge(service.status).name }}</span
                    >
                  </td>
                  <td class="pt-4 pb-4">
                    <router-link
                      :to="{
                        name: 'service.basic-info',
                        params: { id: service.id },
                      }"
                      title="Visualizar"
                    >
                      <i class="flaticon-cogwheel-2"></i>
                    </router-link>
                    <a
                      v-if="$authorization.can('service-destroy')"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#service-destroy"
                      @click="setService(service, index)"
                      title="Excluir serviço"
                    >
                      <i class="flaticon-delete ml-3"></i>
                    </a>
                  </td>
                </tr>
              </u-table>

              <div class="row">
                <div class="col-6 mt-3">
                  Mostrando de {{ this.services.from }} até
                  {{ this.services.to }} de {{ this.services.total }} registros
                </div>
                <div class="col-md-6 pull-right">
                  <b-pagination
                    size="md"
                    align="right"
                    :total-rows="services.total"
                    :per-page="services.per_page"
                    v-model="services.current_page"
                    @change="changePage"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
          <a
            href="#"
            @click.prevent="downloadJsonServices"
            v-if="$authorization.can('service-download-json')"
            class="pull-right text-primary"
            >download JSON</a
          >
        </div>
      </div>
    </div>
    <u-modal id="service-store" title="Adicionar Serviço">
      <div slot="body" ref="form">
        <div class="form-group">
          <label class="form-control-label">Nome</label>
          <input type="text" class="form-control" v-model="service.name" />
        </div>
        <div
          class="form-group m-form__group"
          v-show="$store.state.auth.user.profile <= 2"
        >
          <label>Provedor de Conteúdo</label>
          <select class="form-control m-input" v-model="service.company_id">
            <option selected value>
              Selecione o provedor/parceiro deste serviço.
            </option>
            <option
              v-if="companiesContent.length"
              v-for="(company, index) in companiesContent"
              :key="index"
              :value="company.id"
            >
              {{ company.name }}
            </option>
          </select>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Fechar
        </button>
        <button
          v-show="!isCreating"
          type="button"
          class="btn btn-primary"
          @click="store()"
        >
          Criar
        </button>
        <button
          v-show="isCreating"
          type="button"
          class="btn btn-primary m-loader m-loader--light m-loader--right"
        >
          Criando...
        </button>
      </div>
    </u-modal>

    <u-modal id="export-xlsx" title="Exportar para Excel">
      <div slot="body" ref="form">
        <div class="form-group">
          <label class="form-control-label">Apenas ofertas vigentes?</label>
          <div class="m-radio-inline mt-3">
            <label class="m-radio m-radio--success">
              <input
                type="radio"
                name="type"
                v-model="filter.validOnly"
                value="true"
              />
              Sim
              <span></span>
            </label>
            <label class="m-radio m-radio--focus">
              <input
                type="radio"
                name="type"
                v-model="filter.validOnly"
                value="false"
              />
              Não
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Fechar
        </button>
        <button
          v-show="!downloading"
          type="button"
          class="btn btn-primary"
          @click="exportXlsx()"
        >
          Baixar
        </button>
        <button
          v-show="downloading"
          type="button"
          class="btn btn-primary m-loader m-loader--light m-loader--right"
        >
          Preparando Download...
        </button>
      </div>
    </u-modal>

    <u-modal id="service-destroy" title="Excluir Serviço">
      <div slot="body" ref="form">
        <p>Deseja realmente excluir este serviço?</p>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Fechar
        </button>
        <button
          v-show="!isCreating"
          type="button"
          class="btn btn-danger"
          data-dismiss="modal"
          @click="destroy()"
        >
          Excluir
        </button>
      </div>
    </u-modal>
  </div>
</template>

<script>
import toastr from "toastr";
import VSelect from "vue-select";
import UTable from "@/components/UTable";
import UModal from "@/components/UModal";
import ServiceService from "../services/ServiceService";
import Suport from "../support";

export default {
  name: "services",
  components: {
    UTable,
    UModal,
    VSelect,
  },
  data() {
    return {
      isLoading: true,
      isCreating: false,
      support: Suport,
      service: {
        name: "",
        company_id: null,
      },
      serviceIndex: "",
      serviceService: new ServiceService(),
      services: {},
      servicesNotFound: false,
      filter: {
        service: null,
        company: null,
        responsible: null,
        statusRegister: null,
        status: null,
        sharecode: null,
      },
      filterServices: [],
      filterCompanies: [],
      filterResponsibles: [],
      filterStatusRegister: [],
      filterStatus: [],
      companiesContent: [],
      downloading: false,
      page: 1,
      columns: [
        "Serviço",
        "Provedor",
        "Responsável",
        "Última Atualização",
        "Cadastro",
        "Status",
        "",
      ],
    };
  },
  created() {
    if (localStorage.getItem("serviceFilter")) {
      this.filter = JSON.parse(localStorage.getItem("serviceFilter"));
    }

    if (this.$authorization.can("services")) {
      this.all();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    exportXlsx() {
      this.downloading = true;
      axios({
        url: `/service/report?validOnly=${this.filter.validOnly}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          let name = "servicos.xlsx";
          link.setAttribute("download", name);
          document.body.appendChild(link);
          this.downloading = false;
          link.click();
        })
        .catch((error) => {
          this.downloading = false;
          console.log(error);
        });
    },
    all() {
      this.isLoading = true;

      return this.serviceService
        .all(this.page, this.filter)
        .then((response) => {
          this.services = response.data.services;
          this.companiesContent = response.data.companiesContent;
          this.filterServices = response.data.filterServices;
          this.filterCompanies = response.data.filterCompanies;
          this.filterResponsibles = response.data.filterResponsibles;
          this.filterStatusRegister = response.data.filterStatusRegister;
          this.filterStatus = response.data.filterStatus;
          this.isLoading = false;
        })
        .catch((error) => {
          this.servicesNotFound = true;
          this.isLoading = false;
          console.log(error.response);
        });
    },
    filterService(value, type) {
      this.page = "1";
      this.filter[type] = value;
      localStorage.setItem("serviceFilter", JSON.stringify(this.filter));

      if (!this.isLoading) {
        this.all();
      }
    },
    setService(service, serviceIndex) {
      this.service = service;
      this.serviceIndex = serviceIndex;
    },
    store() {
      if (this.validate()) {
        this.isCreating = true;
        this.lockForm(true);
        this.serviceService
          .store(this.service)
          .then((response) => {
            this.service = {};
            this.isCreating = false;
            $("#service-store").modal("hide");

            this.$router.push({
              name: "service.basic-info",
              params: { id: response.data.id },
            });
          })
          .catch((error) => {
            console.log(error);
            this.isCreating = false;
            this.lockForm(false);

            swal("Ops!", this.$helper.formRequestErrorsMessage(error), "error");
          });
      } else {
        this.isCreating = false;
        this.lockForm(false);
      }
    },
    destroy() {
      this.isLoading = true;
      this.serviceService
        .destroy(this.service)
        .then((response) => {
          this.all();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    lockForm(lock) {
      const form = $(this.$refs.form);
      if (lock) {
        form.find("input, select").prop("disabled", true);
      } else {
        form.find("input, select").prop("disabled", false);
      }
    },
    validate() {
      toastr.clear();
      if (!this.service.name) {
        toastr.error("O campo nome é obrigatório ");
        return false;
      }
      return true;
    },
    changePage() {
      this.isLoading = true;
      setTimeout(() => {
        this.page = this.services.current_page;
        this.all();
      }, 100);
    },
    downloadJsonServices() {
      let providerID = false;
      if (this.filter.company) {
        providerID = this.filter.company.value;
      }
      window.open(`${baseURL}/services/json/download?provider=${providerID}`);
    },
  },
};
</script>

<style>
.filter-input {
  border: 1px solid #c0c0c0;
}
</style>
